@import '../../../../variables';

.subheader {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 11px;
  margin-bottom: 11px;
  position: relative;
  z-index: 1;

  .back {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    position: absolute;

    img {
      margin-right: 8px;
    }
  }

  .hint {
    display: flex;
    align-items: center;
    position: absolute;
    right: 5px;

    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
    }

    .hint-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      font-size: 24px;
      line-height: 36px;
      color: $color-white;
      width: 36px;
      height: 36px;
      margin-left: 3px;
      padding-top: 2px;
      background-image: url('./img/bg-hint.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
    }
  }

  h3 {
    margin-left: auto;
    margin-right: auto;
    font-weight: 900;
    font-size: 24px;
    line-height: 38px;
  }
}

@media (max-height: 750px) {
  .subheader {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}