@import '../../variables.scss';

.home-component {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;

  .tutorial-globe {
    position: relative;
  }

  .slider-container {
    width: 317px;
    height: 125px;
    max-width: 84.53%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .slider-tutorial {
    background: rgba($color-white, 0.9);
    border-radius: 42px;
    backdrop-filter: blur(4px);
    filter: drop-shadow(0px 10px 20px rgba($color-black, 0.3));
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      margin-top: 0px;
      left: 89px;
      width: 41px;
      height: 24px;
      background-image: url('./img/arrow-down.png');
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 1;
      opacity: 0.9;
      backdrop-filter: blur(4px);
    }

    .slick-arrow {
      box-shadow: 0 10px 20px rgba($color-black, 0.3);
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }

    .slick-prev {
      left: -20px;
    }

    .slick-next {
      right: -20px;
    }

    .item {
      position: relative;
      padding: 20px 25px 18px;
      box-sizing: border-box;

      p {
        margin-top: 5px;
        font-size: 14px;
        line-height: 18px;

        span {
          font-weight: 800;
          font-size: 36px;
          line-height: 40px;
        }
      }
    }
  }

  .action-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -18px;
    z-index: 2;
  }

  .skip {
    display: inline-block;
    color: $color-blue-2;
    background: rgba(255, 255, 255, 0.8) linear-gradient(180deg, $color-white 0%, rgba($color-white, 0) 54.17%, $color-gray-1 100%);
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));
    border-radius: 8px;
    padding: 10px 22px;
    position: relative;
    right: 0px;
    bottom: 0px;
    margin-right: 27px;
    margin-bottom: 10px;
    z-index: 1;
  }

  .globy {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -25px;
    max-width: 286px;
    //max-height: 30vh;
  }

  .prizes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -40px;
    
    .prize {
      position: relative;
      z-index: 2;

      & > a {
        display: block;
      }

      .prize-img, p {
        position: absolute;
        z-index: 1;
      }

      &:first-child {
        .prize-img {
          width: 120px;
          right: 7px;
          top: 26px;
        }

        p {
          left: 12px;
        }
      }

      &:nth-child(2) {

        .prize-img {
          width: 102px;
          top: 6px;
          left: 16px;
        }

        p {
          right: 12px;
        }
      }

      p {
        font-weight: 800;
        min-width: 103px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color-white, 0.8) linear-gradient(180deg, $color-white 0%, rgba($color-white, 0) 54.17%, $color-gray-1 100%);
        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));
        border-radius: 8px;
        top: 93px;
        padding-left: 5px;
        padding-right: 5px;
        box-sizing: border-box;
        white-space: nowrap;
      }
    }
  }

  .btn {
    margin-top: -30px;
  }

  .warning-message {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 15px;
    background-color: $color-yellow-1;
    display: flex;
    align-items: center;

    img {
      margin-right: 20px;
    }

    p {
      font-size: 14px;
      line-height: 23px;
    }
  }
}

@media (max-height: 750px) {
  .home-component {
    .tutorial-globe {

    }

    .slider-tutorial {
      .item {
        padding-top: 15px;

        p {
          span {
            font-size: 30px;
            line-height: 32px;
          }
        }
      }
    }

    .prizes {
      margin-top: -50px;

      .prize {
        &:first-child {
          .prize-img {
            width: 110px;
          }
        }

        img:first-of-type {
          height: 165px;
        }

        p {
          min-width: 92px;
          height: 37px;
        }
      }
    }
  }
}

@media (max-height: 570px) {
  .home-component {
    .prizes {
      .prize {
        &:first-child {
          .prize-img {
            width: 85px;
            right: 23px;
          }
        }

        &:nth-child(2) {
          .prize-img {
            width: 80px;
            left: 7px;
          }
        }

        img:first-of-type {
          height: 150px;
        }

        p {
          top: 74px;
        }
      }
    }

    .warning-message {
      padding: 5px 10px;

      p {
        line-height: 20px;
      }
    }
  }
}