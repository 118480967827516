@import "../../../../variables";

.achievements-details-component {
  padding-top: 9px;
  padding-bottom: 20px;

  .achievements-details-box {
    padding: 17px;
    text-align: center;

    .icon-achievement {
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      line-height: 30px;
    }

    .date-unlock {
      font-size: 14px;
      line-height: 23px;
      margin-top: 54px;
      margin-bottom: 20px;
      padding-left: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .btn-share {
      display: block;
      text-align: right;
    }
  }
}