@import '../../../../variables';

.bonus-kit-component {
  position: relative;

  .video-wrapper {
    display: flex;
    justify-content: center;
    //height: 100%;
    flex: 1;
    overflow: hidden;
    filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15));
    //object-fit: cover;
    border-radius: 20px;
    position: relative;

    .video-inner {
      position: absolute;
      top: 0;
      bottom: 0;
      overflow: hidden;
      border-radius: 20px;
    }

    .video-raffle {
      height: 100%;
      //flex: 1 1;
      //overflow: hidden;
      //filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15));
      //border-radius: 20px;
      //object-fit: cover;
    }
  }

  .btn-redeem {
    position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
  }

  .prize {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    padding-top: 4.43vh;
    pointer-events: none;

    .congrats {
      position: relative;
      width: 33.13vh;
      margin-left: auto;
      margin-right: auto;

      .text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;

        p {
          position: relative;
          top: 42%;
          transform: translateY(-50%);
          font-size: 24px;
          color: $color-white;
          text-transform: uppercase;
          font-family: 'Kid Games';
        }
      }

      img {
        display: block;
        width: 100%;
      }
    }

    span {
      font-family: Catamaran;
      font-weight: 400;
      font-size: 1.8vh;
      line-height: 1;
      color: $color-text;
    }

    & > p {
      width: 78%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1.4vh;
      font-family: Catamaran;
      font-weight: 900;
      font-size: 6vh;
      line-height: 1;
      color: $color-text;
    }
  }
}