@import '../../variables.scss';

.loader-page {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gradient-bg;
  z-index: 1;

  .loader-wrapper {
    text-align: center;

    img {
      display: block;
      width: 80px;
    }

    p {
      font-size: 18px;
      line-height: 30px;
      margin-top: 2px;
    }
  }
}