@import '../../variables.scss';

.buy-button {
  width: 61px;
  height: 46px;
  font-weight: 800;
  font-size: 11px;
  line-height: 14px;
  color: $color-white;
  text-transform: uppercase;
  background: linear-gradient(360deg, $color-purple-4 0%, $color-purple-5 100%);
  border: 1px solid $color-purple-6;
  padding: 9px 3px 7px;
  background-color: $color-white;
  filter: drop-shadow(0px 10px 20px rgba($color-black, 0.3));
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}