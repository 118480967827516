@import "../../../../variables";

.position-component {
  width: 51px;
  height: 51px;
  background-image: url('./img/position-normal.png');
  -webkit-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  &.position-gold {
    background-image: url('./img/position-gold.png');
  }

  &.position-silver {
    background-image: url('./img/position-silver.png');
  }

  &.position-bronze {
    background-image: url('./img/position-bronze.png');
  }

  .position-inside {
    background-color: $color-white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-weight: 800;
      line-height: 1;
    }
  }
}