@import "../../../../../variables";

.signup-component {
  .phone-form {
    .terms {
      margin-bottom: 28px;
      margin-left: 7%;
      margin-right: 7%;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;

      a {
        color: $color-purple-12;
      }
    }
  }

  .step-2-signup {
    .instructions-auth {
      margin-bottom: 20px;
    }
  }
}