@import "../../variables";

.world-map {
  .header {
    .bg-gradient {
      display: block;
    }
  }

  .layout-inner {
    background-image: none;
  }
}

.world-map-component {

  &.content-wrapper {
    margin: 0;
    margin-top: -13%;
  }

  .map-wrapper {
    .map-place {
      position: fixed;
      width: 2.7%;
      z-index: 99999;
    }

    //.barcelona {
    //  left: 46.3%;
    //  top: 35.5%;
    //}
    //
    //.berlin {
    //  left: 50.3%;
    //  top: 30%;
    //}
    //
    //.capetown {
    //  left: 51%;
    //  top: 77%;
    //}
    //
    //.dubai {
    //  left: 60.7%;
    //  top: 44.3%;
    //}
    //
    //.lisabona {
    //  left: 44%;
    //  top: 37.5%;
    //}
    //
    //.londra {
    //  left: 46.3%;
    //  top: 27%;
    //}
    //
    //.paris {
    //  left: 47.9%;
    //  top: 30.5%;
    //}
    //
    //.roma {
    //  left: 50.3%;
    //  top: 36.7%;
    //}
    //
    //.singapore {
    //  left: 73.5%;
    //  top: 56.8%;
    //}
    //
    //.rio {
    //  left: 34.5%;
    //  top: 75.5%;
    //}

    img {
      display: block;
    }
  }

}