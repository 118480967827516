@import "../../../../variables";

.signin-component {
  .form-container {
    .form-title {
      position: relative;
      font-weight: 900;
      font-size: 24px;
      line-height: 38px;
      margin-top: -50px;
      margin-bottom: 10px;
      z-index: 1;
    }

    .forgot-pass-link {
      font-weight: 800;
      line-height: 26px;
      color: $color-purple-12;
      margin-top: -9px;
      display: block;
    }
  }

  .no-account {
    padding-bottom: 30px;

    p {
      font-size: 20px;
      line-height: 33px;
      margin-bottom: 20px;
    }

    .btns {
      display: flex;
      justify-content: center;
      margin: 0 -10px;
      padding-left: 20px;
      padding-right: 20px;

      a {
        width: 150px;
        max-width: 50%;
        height: 46px;
        line-height: 48px;
        font-weight: 800;
        font-size: 16px;
        color: $color-purple-12;
        background: linear-gradient(180deg, $color-white 0%, rgba($color-white, 0) 54.17%, $color-gray-1 100%);
        background-color: $color-white;
        filter: drop-shadow(0px 10px 8px $color-blue-12);
        border-radius: 8px;
        display: block;
        margin: 0 10px;
      }
    }
  }
}