@import "../../../variables";

.auth-component {
  text-align: center;
  background: linear-gradient(180deg, rgba($color-white, 0) 0%, $color-blue-4 100%), $color-white;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  .page-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;

    .back-btn {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      line-height: 38px;

      img {
        vertical-align: middle;
      }
    }

    h3 {
      font-weight: 900;
      font-size: 24px;
      line-height: 38px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .form-container {
    background: rgba($color-white, 0.8);
    backdrop-filter: blur(4px);
    filter: drop-shadow(0px 10px 20px rgba($color-black, 0.15));
    border-radius: 20px;
    padding-top: 15px;
    padding-bottom: 20px;
    box-sizing: border-box;
    //min-height: 75.5vh;
    margin-bottom: 30px;

    .prize-wrapper {
      max-width: 300px;
      position: relative;
      margin: 0 auto;

      .prize {
        position: absolute;
        bottom: 21%;
        right: 0;
        left: 0;
        margin: auto;
      }
    }


    .row-phone {
      display: flex;
      justify-content: center;
      max-width: 375px;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;

      .form-field {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        flex: 1;
      }

      .prefix {
        width: 70px;
        padding-right: 10px;
        flex: initial;
      }
    }

    .form-field {
      margin-bottom: 10px;
      max-width: 375px;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;

      input {
        border-width: 2px;
        text-align: left;
      }
    }

    .submit-input-group {
      margin-left: -20px;
      margin-right: -20px;
    }

    .submit-btn {
      background-image: url('./img/submit-input-bg.svg');
      background-size: 100% 100%;
      background-color: transparent;
      border: none;
      backdrop-filter: none;
      border-radius: 18px;
      width: 100%;
      height: 106px;
      filter: none;
      box-shadow: none;
      padding: 0 0 16px 0;
      font-weight: 800;
      font-size: 20px;
      color: $color-white;
      text-shadow: 0 3px 0 rgba($color-black, 0.25);
      margin-top: -10px;
      display: block;
      line-height: 90px;
    }
  }
}

.small-form-component {
  display: flex;
  flex-direction: column;

  .instructions-auth {
    padding-top: 30px;
    margin-bottom: 10px;
  }

  .form-container {
    padding-bottom: 0;
  }

  .prize-wrapper {
    max-width: 300px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .prize {
      position: absolute;
      bottom: 21%;
      right: 0;
      left: 0;
      margin: auto;
    }
  }

}