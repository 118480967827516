@import '../../variables.scss';

.btn {
  font-weight: 900;
  font-size: 48px;
  line-height: 77px;
  display: block;
  text-align: center;
  color: $color-white;
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.25);
  width: 320px;
  height: 161px;
  max-width: 100%;
  background-image: url('./img/bg-fat-button.png');
  padding-top: 14px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  background-size: contain;

  &.btn-slim {
    font-size: 36px;
    line-height: 58px;
    height: 131px;
    background-image: url('./img/bg-slim-button.png');
    padding-top: 12px;

    &.disabled {
      background-image: url('./img/bg-slim-button-disabled.png');
    }
  }

  &.disabled {
    background-image: url('./img/bg-fat-button-disabled.png');
    pointer-events: none;
  }
}

@media (max-height: 750px) {
  .btn {
    font-size: 39px;
    line-height: 64px;
    width: 256px;
    height: 129px;
    background-size: contain;

    &.btn-slim {
      font-size: 32px;
      line-height: 49px;
      width: 256px;
      height: 105px;
    }
  }
}