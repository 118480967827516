@import "../../variables";

.App.tutorial .layout-inner {
  background: $gradient-bg;
}

.App.tutorial .main {
  overflow: auto;
}

.tutorial-component {
  padding-top: 11px;

  .box-shadow {
    padding: 17px;
    margin-bottom: 10px;
  }

  .tutorial-item {

    &.opened {

      .title {
        .arrow {
          img {
            transform: rotate(-180deg);
          }
        }
      }

      .content {
        max-height: 1000px;
        padding-top: 13px;
        opacity: 1;
      }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        flex: 1;
      }

      .arrow {
        margin-left: 20px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        img {
          transition: transform .2s ease-in-out;
        }
      }
    }

    .content {
      max-height: 0;
      overflow: hidden;
      transition: all .2s ease-in-out;
      opacity: 0;

      .content-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 13px;
      }

      p {
        padding-bottom: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
      }
    }
  }
}