@import "../../variables";

.App.prizes .layout-inner {
  background: $gradient-bg;
}

.App.prizes .main {
  overflow: auto;
}

.prizes-component {
  .grand-prize {
    .prize-title {
      background-color: $color-purple-1;
    }
  }

  .monthly-prize {
    .prize-title {
      background-color: $color-blue-8;
    }
  }

  .weekly-prize {
    .prize-title {
      background-color: $color-green-7;
    }
  }

  .prize-box {
    margin-bottom: 20px;
    //height: 171px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .prize-title {
      margin-bottom: 9px;
    }

    .about-prize {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 14px 16px;
      margin-top: auto;
      margin-bottom: auto;

      .prize-img {
        width: 47.84%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        text-align: center;

        img {
          max-height: 95px;
        }
      }

      .prize-info {
        flex: 1;
        text-align: center;

        p {
          font-weight: 800;
          font-size: 36px;
          line-height: 44px;

          span {
            font-weight: bold;
            line-height: 31px;
            margin-bottom: 4px;
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .prizes-component {
    .prize-box {
      .about-prize {
        padding-left: 10px;
        padding-right: 10px;

        .prize-info {
          p {
            font-size: 32px;
            line-height: 28px;
          }
        }
      }
    }
  }
}