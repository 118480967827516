@import '../../../variables.scss';

.result-game-component {
  display: flex;
  flex-direction: column;
  height: 100%;

  .wrapper-game {
    overflow: hidden;
  }

  .title-result {
    font-family: 'Kid Games';
    font-size: 36px;
    line-height: 41px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    background: linear-gradient(92.09deg, $color-blue-13 18.95%, $color-blue-14 97.1%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .results-list {
    .item {
      display: flex;
      align-items: center;
      padding-left: 12px;
      padding-right: 20px;
      margin-bottom: 20px;

      .img {
        width: 44px;
        text-align: center;
        margin-right: 10px;
      }

      .info {
        flex: 1;

        .description {
          font-size: 14px;
          line-height: 23px;
          margin-top: -2px;
        }
      }

      .xp {
        margin-left: auto;

        p {
          font-weight: 900;
          font-size: 36px;
          line-height: 59px;

          span {
            font-size: 24px;
            line-height: 39px;
            margin-left: 6px;
            display: inline-block;
          }
        }
      }
    }
  }

  .total {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    padding-left: 25px;
    padding-right: 20px;
    background: linear-gradient(180deg, rgba(30, 29, 82, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    background-blend-mode: multiply;
    border-radius: 45px;
    box-sizing: border-box;

    p {
      font-weight: 900;
      font-size: 24px;
      line-height: 39px;
    }

    .xp {
      font-size: 36px;
      line-height: 59px;
      color: $color-blue-13;
      margin-left: auto;

      span {
        margin-left: 6px;
        display: inline-block;
        color: $color-text;

        &:first-child {

        }

        &:last-child {
          font-size: 24px;
          line-height: 39px;
        }

      }
    }
  }

  .btn {
    margin-top: 14px;
  }
}

@media (max-width: 360px) {
  .result-game-component {
    .title-result {
      font-size: 30px;
      line-height: 35px;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .results-list {
      .item {
        padding-left: 10px;
        padding-right: 15px;
        margin-bottom: 10px;

        .img {
          width: 12%;
        }

        .info {
          width: 55%;
        }

        .xp {
          width: 33%;
          text-align: right;

          p {
            font-size: 30px;
            line-height: 50px;

            span {
              font-size: 20px;
              margin-left: 3px;
            }
          }
        }
      }
    }

    .total {
      height: 70px;

      p {
        font-size: 20px;
      }

      .xp {
        font-size: 30px;
        line-height: 50px;

        span {
          margin-left: 3px;

          &:last-child {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media (max-height: 700px) {
  .result-game-component {
    .title-result {
      font-size: 30px;
      line-height: 35px;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .results-list {
      .item {
        padding-left: 10px;
        padding-right: 15px;
        margin-bottom: 10px;

        .img {
          width: 12%;
        }

        .info {
          width: 55%;
        }

        .xp {
          width: 33%;
          text-align: right;

          p {
            font-size: 30px;
            line-height: 50px;

            span {
              font-size: 20px;
              margin-left: 3px;
            }
          }
        }
      }
    }

    .total {
      height: 70px;

      p {
        font-size: 20px;
      }

      .xp {
        font-size: 30px;
        line-height: 50px;

        span {
          margin-left: 3px;

          &:first-child {
            font-size: 30px;
          }

          &:last-child {
            font-size: 20px;
          }
        }
      }
    }
  }
}