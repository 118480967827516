@import "../../../../variables";

.profile-edit-component {
  padding-bottom: 100px;
  overflow-x: hidden;

  .edit-info-user {
    .form-field {
      margin-bottom: 20px;
      text-align: center;

      &:last-child {
        margin-bottom: 10px;
      }

      input[type="text"] {
        font-weight: 900;
        font-size: 24px;
      }
    }
  }

  .separator-word {
    font-size: 24px;
    line-height: 39px;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 1px;
      width: 42%;
      background-color: $color-gray-4;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  .edit-avatar-user {
    padding-top: 13px;

    p {
      text-align: center;
      margin-bottom: 13px;
    }

    .avatars-list {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      margin-left: -10px;
      margin-right: -10px;

      .avatar-box {
        width: 50%;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        box-sizing: border-box;

        .box-shadow {
          padding: 10px 25px;
          height: 163px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 100%;
          }
        }

        &.selected {
          .box-shadow {
            box-shadow: inset 0 0 0 3px $color-blue-5;
          }
        }
      }
    }
  }

  button[type="submit"] {
    appearance: none;
    border: 0;
    background-color: transparent;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -25px;
  }
}