@import '../../variables.scss';

.header {
  position: relative;
  z-index: 5;

  .bg-gradient {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 150%;
    width: 100%;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0) 13.24%, #FFFFFF 50.79%);
  }

  .header-top {
    display: flex;
    position: relative;
    flex-direction: column;
    z-index: 1;

    .bg-header {
      width: 100%;
      z-index: 0
    }

    .logo {
      width: 70.66%;
      min-height: 49%;
      margin-left: auto;
      margin-right: auto;
      background-image: url('./img/bg-button-header.svg');
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 1.9%;
      padding-top: 1%;
      margin-bottom: 2%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      img {
        width: 90%;
        margin-top: 3%;
      }
    }

    .hamburger-menu,
    .user-avatar {
      position: absolute;
      bottom: 0;
      width: 12%;
      padding-bottom: 12%;
      border-radius: 50%;
      margin-bottom: 2%;
      background-size: cover;
    }

    .hamburger-menu {
      left: 1.5%;
      background-image: url('./img/bg-hamburger-menu.png');

      .icon-hamburger {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 53.35%;
      }
    }

    .user-avatar {
      right: 1.5%;
      background-image: url('./img/bg-avatar.png');
      overflow: hidden;

      img {
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        min-width: 91%;
        min-height: 91%;
      }
    }
  }

  .header-bottom {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    .tickets,
    .level {
      //width: 47.5%;
      //padding-bottom: 11.54%;
      //background-size: contain;
      //background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      width: 178px;
      max-width: 47.5%;
      margin-top: -8px;
      position: relative;

      .number-container {
        background-size: contain;
        background-repeat: no-repeat;
        width: 63px;
        height: 36px;
        text-align: center;

        p {
          font-size: 11px;
          line-height: 1;
          margin-top: 6px;
        }

        span {
          font-weight: 800;
          font-size: 16px;
          line-height: 12px;
          display: block;
        }
      }
    }

    .tickets {
      .number-container {
        background-image: url('./img/number-tickets.png');
        position: relative;
      }

      .icon-ticket {
        position: absolute;
        left: -2px;
        top: 0;
      }

      p, span {
        margin-left: 9px;
        z-index: 1;
        position: relative;
      }

      .list-tickets {
        flex: 1;
        display: flex;
        margin-top: 5px;

        img {
          width: 20%;
        }
      }
    }

    .level {
      .info {
        padding-left: 10px;
      }

      .number-container {
        background-image: url('./img/number-level.png');
        order: 2;
      }

      .level-progress {
        flex: 1;
        padding-right: 3px;

        p {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: $color-white;

          span {
            color: $color-white;
          }
        }

        .progressbar {
          width: 100%;
          height: 8px;
          border-radius: 14px;
          overflow: hidden;
          position: relative;

          .progressbar-bg {
            background: linear-gradient(180deg, #989797 0%, rgba(196, 196, 196, 0) 100%);
            mix-blend-mode: multiply;
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 14px;
          }

          span {
            background: linear-gradient(180deg, $color-white 0%, $color-gray-3 100%);
            box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
            border-radius: 14px;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            transition: width .1s ease-out;

            &::after {
              content: '';
              width: 89%;
              height: 2px;
              background: rgba(0, 0, 0, 0.1);
              border-radius: 4px;
              position: absolute;
              bottom: 2px;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
      }
    }

    .info {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding-left: 6px;
      padding-right: 6px;
      box-sizing: border-box;
    }
  }
}

@media (max-height: 600px) {
  .header {
    margin-top: -10px;
  }
}