@import '../../variables.scss';

.footer {
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $color-orange-3;
  display: flex;
  align-items: center;
  z-index: 2;

  .prize-img {
    width: 103px;
    margin-right: 14px;
    margin-top: -20px;
  }

  p {
    line-height: 18px;
    color: $color-white;
    margin-bottom: 4px;

    strong {
      font-weight: 800;
      color: $color-white;
    }
  }
}

@media (max-height: 650px) {
  .footer {
    height: 40px;

    p {
      margin-bottom: 0;
      font-size: 15px;
    }

    .prize-img {
      width: 93px;
      margin-top: -17px;
    }
  }
}