@import '../../variables.scss';

.menu {
  width: 100%;
  position: fixed;
  left: 0;
  //right: 0;
  top: 0;
  bottom: 0;
  padding-top: 15px;
  background-image: url('./img/bg-menu.jpg');
  background-size: cover;
  background-position: top right;
  z-index: 5;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .menu-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 7px;
    padding-left: 20px;
    margin-bottom: 17px;
  }

  .select-languages {
    margin-left: auto;
    margin-right: 22px;
    background-image: url('./img/red-circle.svg');
    background-size: cover;
    background-position: center;
    width: 47px;
    height: 47px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .close {
    width: 47px;
    height: 47px;

    img {
      width: 100%;
    }
  }

  .menu-list {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;

    a {
      display: block;
    }

    .pages {
      padding-bottom: 20px;
      margin-bottom: 17px;
      border-bottom: 2px dashed $color-blue-1;

      a {
        font-weight: 800;
        font-size: 26px;
        line-height: 50px;
        position: relative;

        &.active {
          &::after {
            content: '';
            position: absolute;
            left: -20px;
            top: -4px;
            bottom: 0;
            margin: auto;
            background-image: url('./img/active.png');
            background-repeat: no-repeat;
            background-size: contain;
            width: 16px;
            height: 27px;
          }
        }
      }
    }

    .links {
      a {
        font-size: 16px;
        line-height: 40px;
      }

      .logout-link {
        font-weight: 700;
        margin-top: 10px;
      }
    }
  }

  .ad {
    width: 100%;
    margin-top: auto;
    margin-bottom: 16px;
    padding: 0 6px;
    box-sizing: border-box;

    //img {
    //  width: 100%;
    //}
  }
}

@media (max-height: 750px) {
  .menu {
    .menu-list {
      margin-bottom: 15px;

      .pages {
        padding-bottom: 16px;
        margin-bottom: 13px;

        a {
          font-size: 21px;
          line-height: 38px;
        }
      }

      .links {
        a {
          line-height: 32px;
        }

        .logout-link {
          margin-top: 5px;
        }
      }
    }

    .ad {
      margin-bottom: 5px;
    }
  }
}