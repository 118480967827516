@import '../../../../variables.scss';

.wrapper-game {
  position: relative;
  background: rgba($color-white, 0.8) linear-gradient(180deg, rgba($color-white, 0.8) 0%, rgba($color-white, 0) 54.17%, rgba($color-gray-1, 0.8) 100%);
  backdrop-filter: blur(4px);
  filter: drop-shadow(0 10px 20px rgba($color-black, 0.15));
  border-radius: 20px;
  height: 100%;
  flex: 1;
  //overflow: hidden;

  .progress-bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 80%;
  }
}

@media (max-height: 600px) {
  .wrapper-game {
    .progress-bar {
      bottom: 15px;
    }
  }
}