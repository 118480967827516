@import '../../variables.scss';

.loader {
  background-image: url('./img/fundal-loading.jpg');
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .logo-img {
    margin-top: 16vh;

    img {
      max-height: 129px;
    }
  }

  .loading {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      width: 80px;
    }

    p {
      font-size: 18px;
      line-height: 30px;
      margin-top: 2px;
    }
  }

  .banner-logo {
    margin-top: auto;
    margin-bottom: 40px;
    padding-top: 10px;
    max-width: 93%;
  }
}

@media (max-height: 620px) {
  .loader {
    .logo-img {
      margin-top: 15vh;

      img {
        max-height: 100px;
      }
    }

    .loading {

      .banner-logo {
        margin-bottom: 20px;
      }
    }
  }
}