@import "../../variables";

.App.profile {
  .layout-inner {
    background-image: none;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #DFF2F9 100%), #ffffff;
  }

  .main {
    overflow: auto;
  }
}

.profile-component {
  .info-user {
    padding-top: 20px;
    margin-bottom: 24px;
    text-align: center;

    .avatar {
      display: inline-block;
      position: relative;

      .edit-photo {
        position: absolute;
        right: -46px;
        top: 0;
        z-index: 1;
      }
    }

    .avatar-user {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 120px;
        height: 76px;
        background-image: url('./img/wave-avatar.svg');
      }
    }

    .username {
      font-weight: 900;
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 3px;
    }

    .edit-link {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      color: $color-purple-12;
    }
  }

  .stats-user {
    .stats-row {
      .box-stats {
        .info-1, .info-3 {
          width: auto;
        }
      }
    }

    .box-stats {
      filter: drop-shadow(0px 2px 8px rgba($color-black, 0.2));
      border: 3px solid $color-white;
      box-sizing: border-box;
      border-radius: 20px;
      margin-bottom: 37px;
      padding-left: 20px;
      padding-right: 14px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -3px;
        left: 0;
        right: 0;
        width: 100%;
        padding-bottom: 13%;
        background-image: url('./img/wave-box-top.svg');
        background-size: cover;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        width: 100%;
        padding-bottom: 5%;
        background-image: url('./img/wave-box-bottom.svg');
        background-size: cover;
      }

      .infos-box {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: -17px;
        padding-bottom: 13px;
      }

      .info-1, .info-3 {
        text-align: center;

        img {
          margin-bottom: 5px;
        }
      }

      .text-info {
        width: 61px;
        background: linear-gradient(180deg, $color-white 0%, rgba($color-white, 0) 54.17%, $color-gray-1 100%);
        background-color: $color-white;
        filter: drop-shadow(0px 10px 20px rgba($color-black, 0.3));
        border-radius: 10px;
        text-align: center;
        padding-top: 6px;
        padding-bottom: 2px;
        display: block;
        box-sizing: border-box;

        p {
          font-size: 11px;
          line-height: 18px;
        }

        .number {
          font-weight: 800;
          font-size: 16px;
          line-height: 26px;
          margin-top: -7px;
        }
      }

      .info-xp {
        flex: 1;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;

        p {
          font-weight: bold;
          font-size: 24px;
          line-height: 39px;
          color: $color-white;
          text-shadow: 0 1px 0 rgba($color-black, 0.25);
        }

        .progress {
          width: 100%;
          height: 8px;
          border-radius: 14px;
          position: relative;

          span {
            background: linear-gradient(180deg, $color-white 0%, $color-gray-3 100%);
            box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
            border-radius: 14px;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;

            &::after {
              content: '';
              width: 89%;
              height: 2px;
              background: rgba($color-black, 0.1);
              border-radius: 4px;
              position: absolute;
              bottom: 2px;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
      }

      .buy-btn {
        padding: 9px 3px 7px;
      }

      .tickets-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-left: 17px;
        padding-right: 17px;
        max-width: 145px;
        flex: 1;

        img {
          width: 20%;
        }
      }
    }

    .stats-row {
      display: flex;
      justify-content: space-between;

      .box-stats {
        width: 46.86567%;

        .infos-box {
          justify-content: center;
          text-align: center;
        }

        .text-info {
          width: 110px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .box-xp {
      background: linear-gradient(270.28deg, $color-purple-2 0.22%, $color-purple-3 98.44%);
    }

    .box-tickets {
      background: linear-gradient(270.28deg, $color-green-2 0.22%, $color-green-3 98.44%);
    }

    .box-skills {
      background: linear-gradient(270.28deg, $color-purple-7 0.22%, $color-purple-8 98.44%);
      padding-right: 0;
      padding-left: 0;
    }

    .box-achievments {
      background: linear-gradient(270.28deg, $color-green-4 0.22%, $color-green-5 98.44%);
      padding-right: 0;
      padding-left: 0;
    }

    .box-unsubscribe {
      background: linear-gradient(270.28deg, #B5B3B6 0.22%, #6B6B6B 98.44%);
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;

      .btn-unsubscribe {
        display: flex;
        align-items: center;

        .text-info {
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          margin-left: 24px;
        }
      }
    }
  }
}

@media (max-width: 330px) {
  .profile-component {
    .stats-user {
      .box-stats {
        .info-xp {
          padding-left: 10px;
          padding-right: 10px;

          p {
            font-size: 20px;
          }
        }

        .tickets-list {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}