@import '../../variables.scss';

.box-wave {
  height: 46px;
  font-size: 20px;
  line-height: 33px;
  color: $color-white;
  border: 3px solid $color-white;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url('./img/wave-color-box.svg');
    background-size: cover;
    background-position: center;
    z-index: 0;
  }

  &.box-wave-big {
    width: 100%;
    border: none;
    border-radius: 16px;

    &::after {
      background-image: url('./img/wave-color-box-big.svg');
    }
  }
}